<template>
  <router-view/>
</template>

<style lang="scss">
@import './assets/css/normalize.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'SH-E';
  src: url('./assets/fonts/SourceHanSansCN-ExtraLight.ttf');
}
</style>
